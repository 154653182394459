@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

html,
body,
#__next {
  @apply text-gray-900;
  @apply font-sans;
  @apply antialiased;
  @apply relative;
  @apply bg-cool-gray-100;
  @apply h-full w-full;
  @apply p-0;
  @apply overflow-hidden;
}

a {
  text-decoration: none !important;
}

.md-list-ul ul li {
  list-style-type: disc;
}

.md-list-ol ol li {
  list-style-type: decimal;
}

.h-available {
  height: stretch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Force scrollbar to appear */
.show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  margin: auto;
  width: 10%;
  background-position: bottom center;
  cursor: ns-resize;
  border: 3px solid #b8b8b8;
  border-radius: 50px 50px 0 0;
}
#nprogress .bar {
  background: #5e83ff !important;
}

.commandbar-launcher {
  z-index: 5 !important;
}

/* Styles for date picker */
.rdrDefinedRangesWrapper {
  width: 100% !important;
  padding-right: 1.5rem !important;
}

.rdrDateDisplayItem {
  border-radius: 0px !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #5e83ff !important;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

[type="checkbox"],
[type="radio"] {
  appearance: none;
}

/* FullCalendar */
:root {
  --fc-button-bg-color: #1a4775 !important; /* ave-blue-500 */
  --fc-button-hover-bg-color: #153b60 !important; /* ave-blue-700 */
  --fc-button-active-bg-color: #153b60 !important; /* ave-blue-700 */

  --fc-button-border-color: #153b60 !important; /* ave-blue-700 */
  --fc-button-hover-border-color: #153b60 !important; /* ave-blue-700 */
  --fc-button-active-border-color: #153b60 !important; /* ave-blue-700 */
}

/* TODO: Move this to inline styles in the component */
.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #5e83ff; /* rgba(0, 0, 0, 0.5) */
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: #5e83ff;
  width: 5px;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.react-datepicker__day--outside-month {
  opacity: 50%;
}

.react-datepicker__day-names {
  border: 0px;
}

.react-datepicker__input-time-container {
  align-items: center !important;
}
.react-datepicker-time__input {
  font-size: 1rem !important; /* 16px */
  line-height: 1.5rem !important; /* 24px */
}

.react-datepicker-time__caption {
  font-size: 1rem !important; /* 14px */
  line-height: 1.5rem !important; /* 20px */
  font-weight: 600 !important;
}

.react-datepicker__week {
  gap: 0rem !important;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.react-datepicker__day--range-start {
  border: 1px !important;
  border-color: #006be6 !important;
  border-style: solid !important;
  background: white !important;
  border-radius: 6px 0px 0px 6px !important;
}

.react-datepicker__day--range-end {
  background: #006be6 !important;
  color: white !important;
  border-radius: 0px 6px 6px 0px !important;
}

.react-datepicker__day--in-range {
  background: #f3f4f6;
  border-radius: 0px;
}

.react-datepicker__day--range-start.react-datepicker__day--range-end {
  background: #006be6 !important;
  color: white !important;
  border-radius: 6px !important;
}

.react-datepicker__day--selecting-range-start {
  border: 1px !important;
  border-color: #006be6 !important;
  border-style: solid !important;
  background: white !important;
  border-radius: 6px 0px 0px 6px !important;
}

.react-datepicker__day--in-selecting-range {
  background: #f3f4f6;
  border-radius: 0px;
}

.react-datepicker__day--selecting-range-end {
  background: #006be6 !important;
  color: white !important;
  border-radius: 0px 6px 6px 0px !important;
}

.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
  background: #006be6 !important;
  color: white !important;
  border-radius: 6px !important;
}

/**
  * Playbooks
   */
.drag-handle {
  opacity: 1;
  transition: opacity ease-in 0.2s;
  border-radius: 0.25rem;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.5rem;
  z-index: 50;
  cursor: grab;

  &:hover {
    background-color: var(--gray-100);
    transition: background-color 0.2s;
  }

  &:active {
    background-color: var(--gray-200);
    transition: background-color 0.2s;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  @media screen and (max-width: 600px) {
    display: none;
    pointer-events: none;
  }
}

/**
  * Raw css/html loader
  */
.raw-lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.raw-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid rgb(0 107 230);
  border-radius: 50%;
  animation: raw-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(0 107 230) transparent transparent transparent;
}
.raw-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.raw-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.raw-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes raw-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
